@import url('https://fonts.googleapis.com/css?family=Montserrat|Permanent+Marker&display=swap');

html,body{
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    position: relative;
}

h5{
  color: #000000;
}

.main-container{
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px; /* height of your footer */
}
.react-inputs-validation__msg_identifier{
    color: red !important; 
}

.navbar{
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.09);
    background-color: white;
    z-index: 100000 !important;
}

.nav-container{
    padding-top:10px;
    padding-bottom: 10px;
}

.navbar-toggler{
    font-size: 12px;
}

.nav-link{
    letter-spacing: 2px;
    font-size: 0.7rem;
    color: #5b6779 !important; 
}
.nav-link-selected{
   
    color: #46c183 !important; 
    
}

.nav-link:hover{
    color: #46c183 !important;
}

a{
    color: #5b6779 !important; 
}

a:hover{
    text-decoration: none !important;
    }
    
    .logo{
        max-height: 50px;
    }
    
    .logo-text{
        position: relative;
        font-size: 1.2rem;
        color:#5b6779;
        font-weight: 600;
        letter-spacing: 2px;
    }
    
    .no-text-decoration{
        text-decoration: none !important;
    }



@media(min-width:576px){
    .nav-link{
        padding-left: 1rem !important;
    }
}

 .btn-primary:focus, .btn-primary:active{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.btn-submit{
    width: 250px !important;
    max-width: 250px !important;
}


.home-content{
    padding-top:86px;
}
.form-control:focus {
    border-color: #46c183;
    box-shadow: none;
}

a:focus,button:focus{
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary{
    background: #46c183;
    background:  linear-gradient(46.28deg,#369062,#46c183);
    color: white;
    border: none !important;
}

.btn-primary:hover{
    opacity: 0.9 !important;
}

.footer{
    background: #46c183;
    background:  linear-gradient(46.28deg,#369062,#46c183);
    color: white;
   padding: 10px;
   
    position: absolute;
    bottom: 0;
    width: 100%;
  
}


.footer-text{
    color: white !important;
    letter-spacing: 4px;
    margin: 0px;
    font-size: 0.7rem;

}
.social-icon{
    height: 14px !important; 
    width: 14px !important;
}

.social-icon:hover{
 color:#2196f3 !important;
}


.admin-icon{
    height: 80px !important; 
   
}

.admin-button,.admin-button:focus,.admin-button:active,.btn:focus,.btn:active {
    width: 100% !important;
    columns: #000000 !important;
    border:1px solid #46c183;
}
.dashboard-card:hover{
    border-color: #46c183;
}

.tripgroup-banner{
    min-height: 200px;
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
}



.tripgroup-banner .tripgroup-title{
    margin-bottom: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-weight: 900;
    font-size: 3rem;
    letter-spacing: 6px;
    width: 100%;
}

@media(max-width:450px){
    .tripgroup-banner .tripgroup-title{
        font-size: 1.9rem;
    }
}

.page-content{
    margin-top: 30px;
}



.tripgroup-trip-card{
    position: relative;  
    box-shadow: 0 5px 15px #dcdada, 0 2px 3px #dcdada;
    border-radius: 10px;
}

.tripgroup-trip-card:hover .tripgroup-trip-short-description .description{
    transform: none;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, .1);
}
.portfolio-project-card:hover .portfolio-project-short-description .description .description-title{
    opacity: 1;
    transition: .1s;
}


.portfolio-project-card:hover .portfolio-project-short-description .description .description-text{
    opacity: 1;
    transition: .1s;
}

.portfolio-project-card:hover .portfolio-project-short-description .project-border-top{
    opacity: 1;
    transition: .2s;
}

.tripgroup-trip-image{
    border-radius: 10px 10px 0 0 ;
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
}
.tripgroup-trip-short-description{
    
    
    min-width: 100%;
    overflow: hidden;
    transition: .1s;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 0 0 10px 10px;

}
.tripgroup-trip-short-description .tripgroup-trip-description{
    padding: 15px;
    min-height: 92px;
    display: block;
    transition: 0.2s ease;
    border-radius: 0 0 10px 10px;
}

.tripgroup-trip-short-description .tripgroup-trip-border-top{
    
    height: 3px;
    width: 100%;
    background: #46c183;
    background:  linear-gradient(46.28deg,#369062,#46c183);
}

.tripgroup-trip-short-description .tripgroup-trip-description .tripgroup-trip-description-title{
    color: #000000;
    font-weight: bold;
    font-size: 1rem;
}

.tripgroup-trip-short-description .tripgroup-trip-description .tripgroup-trip-description-price{
    
 font-style: italic;
}

.homepage-trip-description{
    padding: 15px;
    min-height: 30px;
    display: block;
    transition: 0.2s ease;
    border-radius: 0 0 10px 10px;
}

.carousel-img{
    height:400px;
    background-size: cover;
    background-position: center;
    background-repeat: none;
}

.custom-card-header{
    padding: 0.75rem 1.25rem;
}

.email-unread{
    border: 1px solid #46c183 !important;

    background-color: #b1e4a5;
}

.email-details-header{
    border: 1px solid #46c183 ;
    padding: 10px;

}
.list-group-item-action:hover{
    transition: background-color 0.3s ease-in;
}

.webpdf-view{
   width: 110px !important;
   max-width: 110px !important;
}

.webpdf-delete{
    width: 38px !important;
    max-width: 38px !important;
 }

 .media-body{
     padding: 10px;
 }

 .team-img{
     border-radius: 25px;
     transition: box-shadow 0.4s ease-in-out;
     
 }

 .team-row:hover .team-img{
    box-shadow: 0 5px 15px #dcdada, 0 2px 3px #dcdada;
    
 }

 .content-titles{
     font-weight: 700;
 }

 .react-images__header{
     margin-top:86px;
 }

 .trip-detail-list-item{
     width: 100%;
     border: 1px solid #46c183;
 }

 .why-us-icon{
     height: 40px;
     width: 40px;
     fill: #369062 !important;
     stroke: #369062 !important;
 }
.slide-title{
    color: white;
    font-weight: 700;
    letter-spacing: 8px;
}
.slide-description{
    color: white;
    font-weight: 700;
}

.slide-btn{
    background-color: transparent;
    border: 1px solid white;
    color: white !important;
    width: 155px !important;
    max-width: 155px !important;
    transition: color 0.5s, background-color 0.5s ease-in-out;
}


.slide-btn:hover{
    background-color: white;
   
    color: black !important;
   
}